import { MemberTypeEnum } from "../../../../../../../types/shared";
import { TeamAssignmentWizardStepIdentifierEnum, ITeamAssignmentWizardStepsState } from "./types";

const initialTeamAssignmentStepsState: ITeamAssignmentWizardStepsState = {
    [TeamAssignmentWizardStepIdentifierEnum.MemberType]: {
        order: undefined,
        isStepAnswered: undefined,
        isStepActive: undefined,
        answer: MemberTypeEnum.DB
    },
    [TeamAssignmentWizardStepIdentifierEnum.TeamMemberName]: {
        order: 1,
        isStepAnswered: false,
        isStepActive: true,
        answer: {
            firstName: "",
            lastName: "",
            teamMemberType: "",
            contactEmailAddress: "",
            projectUpnDomain: "",
            displayValue: "-"
        }
    },
    [TeamAssignmentWizardStepIdentifierEnum.TeamSelection]: {
        order: 2,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            team: undefined,
            displayValue: "-"
        }
    },    
    [TeamAssignmentWizardStepIdentifierEnum.Country]: {
        order: 3,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            country: "",
            isoString: "",
            displayValue: "-"
        }
    },
    [TeamAssignmentWizardStepIdentifierEnum.DeviceSelection]: {
        order: 4,
        isStepActive: false,
        isStepAnswered: false,
        answer: {
            deviceType: undefined
        }
    },    
    [TeamAssignmentWizardStepIdentifierEnum.StartDate]: {
        order: 5,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            startDate: null,
            isoString: "",
            displayValue: "-"
        }
    },
    [TeamAssignmentWizardStepIdentifierEnum.SubscriptionType]: {
        order: undefined,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            subscriptionType: "Wave",
            displayValue: "-"
        }
    },
    [TeamAssignmentWizardStepIdentifierEnum.Rate]: {
        order: undefined,
        isStepAnswered: undefined,
        isStepActive: undefined,
        answer: {
            rate: 0,
            rateType: undefined,
            rateCurrency: undefined,
            displayValue: "-"
        }
    },
    [TeamAssignmentWizardStepIdentifierEnum.EndDate]: {
        order: undefined,
        isStepAnswered: undefined,
        isStepActive: undefined,
        answer: {
            endDateMode: undefined,
            isoString: undefined,
            displayValue: undefined
        }
    },
    [TeamAssignmentWizardStepIdentifierEnum.Confirmation]: {
        order: 6,
        isStepAnswered: false,
        isStepActive: false,
        answer: {
            confirmation: false
        }
    }
};

export default initialTeamAssignmentStepsState;
