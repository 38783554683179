import React, { useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import { Heading, Text } from "@breakingwave/react-ui-components";
import Layout from "../../../../components/layout";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import { htmlHeadProps, headerProps, footerProps } from "./Training.config";
import * as S from "./Training.styles";

const TrainingV2: React.FC = () => {
    const [videoFinished, setVideoFinished] = React.useState(false);
    const [customSeekingControl, setCustomSeekingControl] = React.useState<boolean>(false);
    const player = React.useRef<ReactPlayer>(null);

    const bypassVideo =
        window.location.href.includes("http://localhost") || window.location.href.includes("breakingwave.dev");


    useEffect(() => {
        let calculatedCurrentTime = 0;
        const video = player.current?.getInternalPlayer();

        if (!bypassVideo && video) {
            const timeupdate = (): void => {
                if (!video?.seeking) {
                    calculatedCurrentTime = video.currentTime;
                }
            };

            const seeking = (): void => {
                const delta = video.currentTime - calculatedCurrentTime;

                if (Math.abs(delta) > 0.01) {
                    video.currentTime = calculatedCurrentTime;
                }
            };

            const ended = (): void => {
                calculatedCurrentTime = 0;
                setVideoFinished(true);
            };

            video.addEventListener("timeupdate", timeupdate);
            video.addEventListener("seeking", seeking);
            video.addEventListener("ended", ended);

            return (): void => {
                window.removeEventListener("timeupdate", timeupdate);
                window.removeEventListener("seeking", seeking);
                window.removeEventListener("ended", ended);
            };
        }
        return undefined;
    }, [customSeekingControl]);

    return (
        <>
            <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                <S.PageTitleSection>
                    <Container isResponsive={true}>
                        <Row>
                            <Column>
                                <Heading variant="h5" weight={300}>
                                        Mandatory risk training
                                </Heading>
                                <S.Hr color="#595959" style={{ margin: "16px 0 24px" }} />
                            </Column>
                        </Row>
                    </Container>
                </S.PageTitleSection>
                <S.BodySection>
                    <Container isResponsive={true}>
                        <Row>
                            <Column md={7}>
                                <Heading variant="h5" weight={300} color="white.75" style={{ marginBottom: 16 }}>
                                        Compliance training
                                </Heading>
                                <S.InformationText color="white.75">
                                    To complete your compliance training you will need to watch the video below. Once complete we will provide the next steps below.
                                </S.InformationText>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <ReactPlayer
                                    url={`${process.env.REACT_APP_VIDEO_ASSETS_CDN}/BW_Training_vExternal.mp4`}
                                    ref={player}
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: "noplaybackrate nodownload"
                                            }
                                        }
                                    }}
                                    onContextMenu={(e: Event) => e.preventDefault()}
                                    playing={false}
                                    controls={true}
                                    pip={false}
                                    width="100%"
                                    height="auto"
                                    onPlay={() => setCustomSeekingControl(true)}
                                    onEnded={(): void => setVideoFinished(true)}
                                    style={{ marginBottom: 48 }}
                                />
                            </Column>
                        </Row>
                        {videoFinished &&
                            <Row>
                                <Column>
                                    <Text color="white.75">
                                        You have now completed the mandatory risk training. Please return to the email.
                                    </Text>
                                </Column>
                            </Row>
                        }
                    </Container>
                </S.BodySection>
            </Layout>
        </>
    );
};

export default TrainingV2;
