import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, Select, Tick, FormControl, FormErrorText } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { teamSelectionStepSchema, TeamSelectionStepsInputs } from "../index.schema";
import { useProjectPageContext } from "../../../../_state/context";
import { IHomeProject } from "../../../../../../../types/projectCatalogue";
import * as S from "../../wizard.styles";

const TeamSelection: React.FC = () => {
    const { state, dispatch } = useTeamAssignmentStepsContext();

    // Fully typed, shorter spelling of the step name for easy access
    const step = state[TeamAssignmentWizardStepIdentifierEnum.TeamSelection];

    const {
        state: {
            selectedProject
        }
    } = useProjectPageContext();

    const homeProject = useMemo<IHomeProject>(() => selectedProject as IHomeProject, []);

    const teamOptions = useMemo(() => homeProject.Teams.sort((a, b) => a.DisplayName > b.DisplayName ? 1 : -1).map((team) => ({
        value: team.UniqueTeamIdentifier,
        label: team.DisplayName
    })), [homeProject]);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<TeamSelectionStepsInputs>({
        resolver: yupResolver(teamSelectionStepSchema),
        defaultValues: {
            team: step.answer.team ? step.answer.team : homeProject.Teams.length === 1 ? homeProject.Teams[0].UniqueTeamIdentifier : undefined
        }
    });

    const processAndGoToNext = (data: TeamSelectionStepsInputs): void => {
        const { team } = data;        

        const teamFromProject = homeProject.Teams.find((t) => t.UniqueTeamIdentifier === team);
        if (teamFromProject) {
            dispatch({
                type: TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_SELECTION,
                payload: {
                    ...step,
                    answer: {
                        team: team,
                        displayValue: teamFromProject.DisplayName
                    }
                }
            });

            dispatch({
                type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
            });
        }
    };

    const goBack = (): void => {
        dispatch({
            type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_PREV_STEP
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Text size={14} weight={500}>
                    Please select a team
                </Text>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <FormControl as="fieldset" style={{ width: 332 }}>
                    <Controller
                        name="team"
                        control={control}
                        render={({ field }) => (
                            <Select
                                ref={field.ref}
                                defaultValue={
                                    step.answer.team
                                        ? {
                                            label: step.answer.displayValue,
                                            value: step.answer.team
                                        }
                                        : homeProject.Teams.length === 1 ? 
                                            {
                                                label: homeProject.Teams[0].DisplayName,
                                                value: homeProject.Teams[0].UniqueTeamIdentifier
                                            } : undefined
                                }
                                onValueChange={(option) => field.onChange(option.value)}
                                placeholder="Select..."
                                options={teamOptions}
                                error={!!errors.team}
                            />
                        )}
                    />
                    {errors.team && <FormErrorText>{errors.team.message}</FormErrorText>}
                </FormControl>
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button variant="secondary" onClick={goBack}>
                    Back
                </Button>
                <Button iconSvg={<Tick />} iconSize={9} onClick={handleSubmit(processAndGoToNext)}>
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default TeamSelection;
