const countries = {
    AT: "Austria",
    BG: "Bulgaria",
    FR: "France",
    DE: "Germany",
    HK: "Hong Kong",
    IE: "Ireland",
    IN: "India",
    PL: "Poland",
    RO: "Romania",
    SG: "Singapore",
    ES: "Spain",
    LK: "Sri Lanka",
    CH: "Switzerland",
    UA: "Ukraine",
    GB: "United Kingdom",
    US: "United States"
};

export default countries;
