import React, { useMemo } from "react";
import dayjs from "dayjs";
import TeamAccordion from "../../../../../../components/accordion/TeamAccordion";
import StepIndicatorCircle from "../../../../../../components/step-indicator-circle";
import { wizardColours } from "../wizard.colours";
import { UserTypeEnum } from "../../../../../../types/shared";
import { useProjectPageContext } from "../../../_state/context";
import { IHomeProject } from "../../../../../../types/projectCatalogue";
import TeamAssignmentWizardSteps from "./steps";
import { TeamAssignmentStepsContext, reducer } from "./_state/context";
import { ITeamAssignmentWizardStepsState, TeamAssignmentWizardStepIdentifierEnum } from "./_state/types";
import initialTeamAssignmentStepsState from "./_state/initialState";
import * as S from "../wizard.styles";

/**
 * TeamAssignment mode of <TeamWizards />.
 */
const TeamAssignmentWizard: React.FC = () => {

    const { 
        state: {
            selectedProject
        } 
    } = useProjectPageContext();

    const initialState = useMemo(() => {
        const homeProject = selectedProject as IHomeProject;

        const buildingState: ITeamAssignmentWizardStepsState = initialTeamAssignmentStepsState;

        let order = 1;

        buildingState[TeamAssignmentWizardStepIdentifierEnum.MemberType].order = undefined;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName].order = order++;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.TeamSelection].order = order++;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.Country].order = order++;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.DeviceSelection].order = order++;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.StartDate].order = homeProject.StartDateRequired ? order++ : undefined;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.SubscriptionType].order = undefined;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.Rate].order = undefined;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.EndDate].order = undefined;
        buildingState[TeamAssignmentWizardStepIdentifierEnum.Confirmation].order = order++;

        if (!homeProject.StartDateRequired) {
            const now = new Date();

            const dayjsNow = dayjs(now);
            const startDateIsoDateTimeString = `${dayjs(now).format("YYYY-MM-DDTHH:mm:ss")}Z`;
            const displayValue = dayjs(now).format("DD MMM YYYY");

            buildingState[TeamAssignmentWizardStepIdentifierEnum.StartDate].answer = {
                startDate: dayjsNow,
                displayValue,
                isoString: startDateIsoDateTimeString
            };
        } else {
            buildingState[TeamAssignmentWizardStepIdentifierEnum.StartDate].answer ={
                startDate: null,
                isoString: "",
                displayValue: "-"
            };     
        }
        
        return buildingState;

    }, [selectedProject]);

    const [state, dispatch] = React.useReducer(reducer, initialState);
    
    const percentage = (): number => {
        const isAnsweredArray = Object.values(state)
            .filter((step) => step.order !== undefined)
            .map((step) => step.isStepAnswered);
        return (isAnsweredArray.filter((val) => val).length / isAnsweredArray.length) * 100;
    };

    React.useEffect(() => {
        if (process.env.REACT_APP_ENV_TYPE === "local") {
            // ---- console log add team assignment steps state only in dev env ---- //
            console.log("Add team assignment steps state: ", state);
        }
    }, [state]);

    return (
        <TeamAssignmentStepsContext.Provider value={{ state, dispatch }}>
            <TeamAccordion
                profileProgressCircleProps={{
                    size: 30,
                    percentage: percentage(),
                    percentagePathColour: wizardColours.memberIcon,
                    userType: UserTypeEnum.User
                }}
                isToggleable={false}
                hasToggleIcon={false}
                defaultOpenState={true}
                homeProjectName={selectedProject.DisplayName}
                teamMemberDisplayName={state[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName].answer.displayValue}
                team={state[TeamAssignmentWizardStepIdentifierEnum.TeamSelection].answer.displayValue}
                country={state[TeamAssignmentWizardStepIdentifierEnum.Country].answer.displayValue}
                deviceType={state[TeamAssignmentWizardStepIdentifierEnum.DeviceSelection].answer.deviceType}
                startDate={state[TeamAssignmentWizardStepIdentifierEnum.StartDate].answer.displayValue}
                rate={state[TeamAssignmentWizardStepIdentifierEnum.Rate].answer.displayValue}
            >
                <S.TeamAccordionBody>
                    <S.StepIndicatorCirclesWrapper>
                        {Object.values(state)
                            .filter((step) => step.order !== undefined)
                            .sort((a, b) => a.order - b.order)
                            .map((step, index) => (
                                <StepIndicatorCircle
                                    key={index}
                                    value={index + 1}
                                    active={step.isStepActive}
                                    completed={!step.isStepActive && step.isStepAnswered}
                                    disabled={!step.isStepActive && !step.isStepAnswered}
                                    style={{ marginRight: 8 }}
                                />
                            ))}
                    </S.StepIndicatorCirclesWrapper>
                    <TeamAssignmentWizardSteps />
                </S.TeamAccordionBody>
            </TeamAccordion>
        </TeamAssignmentStepsContext.Provider>
    );
};

export default TeamAssignmentWizard;
