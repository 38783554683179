import * as yup from "yup";
import dayjs from "dayjs";
import { UserRolesEnum } from "../../../../../../types/userRoles";
import { DeviceTypeEnum } from "../../../../../../types/shared";

type TeamMemberNameStepInputs = yup.InferType<typeof teamMemberNameStepSchema>;
type UpnDomainStepInputs = yup.InferType<typeof upnDomainStepSchema>;
type CountryStepInputs = yup.InferType<typeof countryStepSchema>;
type StartDateStepInputs = yup.InferType<typeof startDateStepSchema>;
type SubscriptionTypeStepInputs = yup.InferType<typeof subscriptionTypeStepSchema>;
type DeviceSelectionStepInputs = yup.InferType<typeof deviceSelectionStepSchema>;
type TeamSelectionStepsInputs = yup.InferType<typeof teamSelectionStepSchema>;
type ConfirmationStepInputs = yup.InferType<typeof confirmationStepSchema>;
export interface ITeamMemberNameStepContext {
    userRole: UserRolesEnum | undefined;
    getAccessToken: () => Promise<string>;
    isAuthenticated: boolean;
    projectDomain: string;  
}

const teamMemberNameStepSchema = yup
    .object({
        firstName: yup.string().trim().required("Please provide the team member's first name"),
        lastName: yup.string().trim().required("Please provide the team member's last name"),
        teamMemberType: yup.string().required("Please select a member type"),
        contactEmailAddress: yup
            .string()
            .email("Please provide a valid team member's email address")
            .trim()
            .test({
                test: (contactEmailAddress: string | undefined, ctx) => {
                    const context = ctx.options.context as ITeamMemberNameStepContext;
                    const isAdmin = context.userRole === UserRolesEnum.GlobalSuperAdmin || context.userRole === UserRolesEnum.ProjectSuperAdmin;

                    // If the projectDomain is not configured in the project catalogue then block unless
                    // they're a GlobalSuperAdmin or ProjectSuperAdmin
                    if (!context.projectDomain && !isAdmin) {
                        return ctx.createError({ 
                            message: "Project domain is not configured.  Only the super admins may add a user to this project." 
                        });
                    }

                    // If we're not an admin then we may only onboard people with the configured 'projectDomain' email
                    if (!isAdmin) {
                        const regex = new RegExp(`${context.projectDomain}$`);
                        if (!regex.test(contactEmailAddress || "")) {
                            return ctx.createError({ 
                                message: `Please provide a '@${context.projectDomain}' email address` 
                            });
                        }
                    }

                    return true;
                }
            })
            .required("Please provide the team member's email address")
    })
    .required();

const upnDomainStepSchema = yup
    .object({
        upnDomain: yup.string().required("Please select a domain")
    });

const countryStepSchema = yup
    .object()
    .shape({
        country: yup
            .object()
            .shape({
                label: yup.string().required("Please select the country you will be based in"),
                value: yup.string().required()
            })
            .required()
    })
    .required(); // https://github.com/jquense/yup/issues/946#issuecomment-657327900

const teamSelectionStepSchema = yup
    .object({
        team: yup.string().required("Please select a team")
    });
    
const startDateStepSchema = yup
    .object({
        startDate: yup
            .date()
            .typeError("Please provide a valid date")
            .min(dayjs().subtract(1, "day"), "Please provide a start date in the future")
            .required("Please tell us when you would like them to start")
    })
    .required();

const subscriptionTypeStepSchema = yup
    .object({
        subscriptionType: yup.bool()
    })
    .required();

const deviceSelectionStepSchema = yup
    .object({
        deviceType: yup.mixed<DeviceTypeEnum>().oneOf(Object.values(DeviceTypeEnum)).required("Please select a device")
    }).required();

const confirmationStepSchema = yup
    .object({
        confirmation: yup.bool().oneOf([true], "Please confirm that you are happy to proceed")
    })
    .required();

export {
    teamMemberNameStepSchema,
    upnDomainStepSchema,
    countryStepSchema,
    startDateStepSchema,
    subscriptionTypeStepSchema,
    deviceSelectionStepSchema,
    teamSelectionStepSchema,
    confirmationStepSchema
};

export type {
    TeamMemberNameStepInputs,
    UpnDomainStepInputs,
    CountryStepInputs,
    StartDateStepInputs,
    SubscriptionTypeStepInputs,
    DeviceSelectionStepInputs,
    TeamSelectionStepsInputs,
    ConfirmationStepInputs
};
